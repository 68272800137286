.easycourts-swiper {
    .MuiBox-root {
        max-width: 90vw;
    }

    .swiper {
        width: 100%;
        height: 100%;
        padding-bottom: 24px;
        .swiper-wrapper {
            padding: 16px 0px;
        }
    }

    .swiper-slide {
        // text-align: center;
        font-size: 18px;
        // background: #fff;
        background: --mui-palette-grey-50;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
