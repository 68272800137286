.ReactChart {
    .axes {
        .Axis-Group .Axis .domainAndTicks {
            g.tick {
                line {
                    stroke: rgba(0, 0, 0, 0);
                }
                .tickLabel {
                    // stroke: rgba(0, 0, 0, 0.15);
                    fill: rgba(0, 0, 0, 0.3) !important;
                }
            }
        }
    }
}
