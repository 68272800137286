@import './common/swiper';
@import './features/dashboard/side-nav-bar';
@import './common/charts';

//override react-router-dom NavLink and Link adding blue and underlines
a {
    color: unset;
    text-decoration: unset;
}

#app {
    height: 100%;
}
html,
body {
    position: relative;
    height: 100%;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.fade-in {
    animation: fadeIn 1s;
}
